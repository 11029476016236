/*! 
	init.js 
*/


$(document).ready( function(){
	
	var loggedout = $('body').hasClass('notlogged');
	
	// Autosize
	if (loggedout) {
		
		$('select').selectpicker();
		
		$('.radio-custom').radio();
		$('.checkbox-custom').checkbox();
		
	} else {
		
		$("[data-toggle=tooltip]").tooltip(); 
		
		
		$('#notesText, .autosize').textareaAutoSize().trigger('input');
		
		$('.same-height').equalHeights();
		
		$('select').selectpicker();
		
		$('.radio-custom').radio();
		$('.checkbox-custom').checkbox();
		
		$('.panel-collapse-store').each(function(){
			
			var id = $(this).attr('id');
			 
			if (id !='notesPanel' && id !='calculatorPanel') {
			 	
			 	//cl(id);
			 	
				localforage.getItem(id, function(err, value) {
				    if (err) { 
					    //console.error('Oh noes!');  
				    } else {   
					    //cl(value);
					      
				        if (value) { 
					       document.getElementById(id+'Title').className = "panel-title";
					       document.getElementById(id+'Body').className = "collapse in";
						} else { 
				           document.getElementById(id+'Title').className = "panel-title collapsed"; 
						   document.getElementById(id+'Body').className = "collapse";    
				        }
				    }
				});
			
			}	
			   
		});	
		
			
		$('.panel-collapse-store .panel-title').on('click',function(e){
			//e.preventDefault();
			var id=$(this).parent().parent().attr('id');
			var collapsed = $(this).hasClass('collapsed');
			var setValue='';
			//cl('COLAPSED:'+collapsed);
			
			var on = (collapsed ? 1 : 0);
			
			if (id=='notesPanel') { 
				setUserSetting('show_sidebar_notes',on);
				//$('#notesText').textareaAutoSize().trigger('input');
				setTimeout("$('#notesText').textareaAutoSize().trigger('input');",100);
				
			} else if (id=='calculatorPanel') { 
				setUserSetting('show_sidebar_calc',on);
			} else {
				localforage.setItem(id, collapsed, function(err, value) { 
					//cl('setLocalForage: "'+id+'" to '+collapsed); 
					
				});
			}
			
			
			
		});

	    initPhoneInput();
	    
	    $("form").submit(function() {
			$.each($(".tel,.phone"), function( index, value ) {
				$(this).val($(this).intlTelInput("getNumber"));
			});
		});
		
		function setUserSetting(key,value) {
			
			$.ajaxSetup({
		        headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') }
		    });
		    
		    $.ajax({
			  method: "POST",
			  url: "/api/user_setting",
			  data: { key: key, value: value }
			}).done(function( msg ) {
			    //cl( "Saved UserSetting '"+key+"'");
			});
			
		}
		
		function getUserSetting(key,func) {
			
			/*$.ajaxSetup({
		        headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') }
		    });
		    */
		    $.ajax({
			  method: "GET",
			  url: "/api/user_setting/"+key
			  //data: { key: key }
			}).done(function( msg ) {
			    
			    func(msg);
			    return msg;
			 });
			
		}
		
		// Notizen Sidebar
		getUserSetting('sidebar_notes_widget',function(value){
			
			if (value) {
				
				//cl('Init Sidebar Notes...');
				
				var autosave,autosync,savetime=1000,synctime=10000;
		
				
				$('#notesText').on('keyup change',function(){
					clearInterval(autosync);
					clearTimeout(autosave);
					autosave = window.setTimeout(setNotesText, savetime);
					autosync = window.setInterval(getNotesText, synctime);
				});
			
				function setNotesText() {
					setUserSetting('sidebar_notes_text',$('#notesText').val());
				}
				
				function getNotesText() {
					
					if (!$('#notesPanelTitle').hasClass('collapsed')) {
						getUserSetting('sidebar_notes_text',function(value){
							if ($('#notesText').val() != value) {
								$('#notesText').val(value).textareaAutoSize().trigger('input');
							}
						});
					}
				}
				
				autosync = window.setInterval(getNotesText, synctime);
						
				
			} else {
				
				//cl('Sidebar Notes disabled.');
			}
				
		});
		
				
		/*
		$('#is-invoice.checkbox-custom',function(){
			console.log('CheckOut...');
			$('#invoice-address').removeClass('text-muted');
				//$('#invoice-address input').attr('disabled','');
				$('#is-invoice .checkbox-label').addClass('text-muted');
				$('#invoice-address input, #invoice-address select, #invoice-address button').prop('disabled', false);
		});
		
		$('#is-invoice.checkbox-custom.checked',function(){
			console.log('Check...');
			$('#invoice-address').addClass('text-muted');
			//$('#invoice-address input').attr('disabled','disabled');
			$('#is-invoice .checkbox-label').removeClass('text-muted');
			$('#invoice-address input, #invoice-address select, #invoice-address button').prop('disabled', true);
		});
		*/
		$(".sidebar-right .toggle").click(function(){
	 		toggleSidebar();
	 		$(this).find('i').tooltip('hide');	
	 		 		
		});
		
		$("#toggle-navbar").click(function(e){
			e.preventDefault();
	 		toggleNavbar();
	 		
	 		//$(this).find('i').toggleClass('fa-bars').toggleClass('fa-arrow-left');	 		
		});
		
		$('a').click(function(){ 
			//jQuery UI incompatible
			//$('a').tooltip('hide'); 
		});
		
		$('.navbar-hide .sidebar-nav ul a').tooltip();
		
		function toggleSidebar() {
			
			$('body').toggleClass('sidebar-hide').toggleClass('sidebar-show');

	 		if ($('body').hasClass('sidebar-show')) {
		 		setUserSetting('show_sidebar',1);
	 		} else {
		 		setUserSetting('show_sidebar',0);
	 		}
	 		$('.dataTable').css('width', '100%');
	 		
		}
		
		
		function toggleNavbar() {
			
			$('body').toggleClass('navbar-hide').toggleClass('navbar-show');

	 		if ($('body').hasClass('navbar-show')) {
		 		setUserSetting('show_navbar',1);
		 		$('.sidebar-nav ul a').tooltip('destroy');
	 		} else {
		 		setUserSetting('show_navbar',0);
		 		$('.sidebar-nav ul a').tooltip();
	 		}
	 		$('.dataTable').css('width', '100%');
	 		
		}
		
		function toggleWidget(w) {
			$('#'+w).toggleClass('hide');
		}
			
		// Lightbox
		
		$('.image-link').magnificPopup({
			type:'image',
			closeOnContentClick:true,
			mainClass: 'mfp-with-zoom', // this class is for CSS animation below
			cursor: 'mfp-zoom-out-cur',
			zoom: {
			    enabled: true, // By default it's false, so don't forget to enable it
			
			    duration: 300, // duration of the effect, in milliseconds
			    easing: 'ease-in-out', // CSS transition easing function 
			
			    // The "opener" function should return the element from which popup will be zoomed in
			    // and to which popup will be scaled down
			    // By defailt it looks for an image tag:
			    opener: function(openerElement) {
			      // openerElement is the element on which popup was initialized, in this case its <a> tag
			      // you don't need to add "opener" option if this code matches your needs, it's defailt one.
			      return openerElement.is('img') ? openerElement : openerElement.find('img');
			    }
			}
			
		});
		
		$('#pageList').on('change.uk.nestable',function(e,i){
			//alert('changed');
			var data = $("#pageList").data("nestable").serialize();
			//alert(JSON.stringify(data));
			console.log(data);
		});
		
	} // end Logged in
	
	
	
});

/* Functions */
	
function cl(e) { console.log(e); }

function initPhoneInput() {

	//var tel = $(".tel,.phone");
	
	$(".tel,.phone").intlTelInput({
		onlyCountries: ["al", "ad", "at", "by", "be", "ba", "bg", "hr", "cz", "dk", 
		  "ee", "fo", "fi", "fr", "de", "gi", "gr", "va", "hu", "is", "ie", "it", "lv", 
		  "li", "lt", "lu", "mk", "mt", "md", "mc", "me", "nl", "no", "pl", "pt", "ro", 
		  "ru", "sm", "rs", "sk", "si", "es", "se", "ch", "ua", "gb"],
		preferredCountries : ['de','dk'],
		nationalMode : true,
		autoHideDialCode : true,
        utilsScript: "js/lib/libphonenumber/utils.js"
    });

}
	    

function showModal(modalId, contentClass, url) {
	var $content = $('#' + modalId + ' .' + contentClass);
	$content.html('');
	$content.load(url,function(e){
		$('.loading').removeClass('loading');
		$("#"+modalId).appendTo('body');
		$("#"+modalId).modal('show');
	});
	$("#"+modalId).on('hidden.bs.modal', function (e) {
		$content.html('');
	});
}

String.prototype.replaceAll = function(target, replacement) {
	return this.split(target).join(replacement);
};