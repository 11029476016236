function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1);
    if (c.indexOf(name) != -1) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function setCookie(sectionName) {
  var lastState = getCookie(sectionName);
  if (lastState == "" || lastState == "off") {
    document.cookie = sectionName + "=on";
  }
  else {
    document.cookie = sectionName + "=off";
  }
}

function setState() {
	
  $('.panel-collapse-store').each(function(){
	  var id = $(this).attr('id');
	  if (getCookie(id) == "" || getCookie(id) == "off") {
		  
	    document.getElementById(id+'Body').className = "collapse";
	    document.getElementById(id+'Title').className = "panel-title collapsed";
	  }
	  else {
	    document.getElementById(id+'Body').className = "collapse in";
	    document.getElementById(id+'Title').className = "panel-title";
	  }
	   console.log(id);
  });
  
  var id2 = 'sidebar-toggle';
  if (getCookie(id2) == "" || getCookie(id2) == "off") {
	    document.getElementById('body').className = "sidebar-collapse sidebar-hide";
  }
  else {
    document.getElementById('body').className = "sidebar-collapse";
  }
  console.log(id2);
  
}

function checkPanels() {
	$('.panel-collapse-store, .sidebar-right .toggle').on('click',function(e){
		e.preventDefault();
		setCookie($(this).attr('id'))	
		console.log('setCookie: '+$(this).attr('id'));
	});
}




//$(document).ready(function(){
	//checkNotes();
	//setState();
	//checkPanels();
//});